<template>
  <base-modal-view title="Upload General Photo" @cancel="cancelClicked">
    <div>
      <form @submit.prevent="uploadPhoto" class="horizontal-spacing flex-column body-2">
        <p class="text-field-title margin-bottom-025 margin-top">General Photo</p>
        <div class="flex-row">
          <input
            multiple
            style="width: 80%"
            accept="image/jpeg, image/png"
            type="file"
            class="vertical-spacing-half input-field"
            id="file"
            ref="photo"
            name="photo"
            v-on:change="handleFileUpload($event)"
          />
          <button
            type="button"
            name="photo"
            class="flex-end vertical-spacing-half no-button-style"
            title="Clear"
            v-on:click="handleFileClear"
          >
            X
          </button>
        </div>
        <p class="margin-top-05" :class="{ error: getFileSize() > 3 }">({{ getFileSize() }}/3MB Used)</p>
        <primary-button type="submit" class="margin-top" :title="loading ? 'Requesting...' : 'Request'" />
      </form>
    </div>
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import BaseModalView from "./BaseModalView.vue";

import { uploadGeneralPhoto } from "@/api/Form.js";
import { mapActions } from "vuex";

export default {
  name: "UploadPhotoModal",
  components: { PrimaryButton, BaseModalView },
  emits: ["cancel", "onPhoto"],
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      photos: [],
      error: null,
      loading: false
    };
  },
  methods: {
    cancelClicked: function () {
      this.$emit("cancel");
    },
    handleFileUpload: function (event) {
      this.photos = event.target.files;
    },
    handleFileClear: function () {
      this.$refs["photo"].value = null;
      this.photos = [];
    },
    getFileSize: function () {
      if (!this.photos.length) {
        return 0;
      }
      var toReturn = 0;
      Array.from(this.photos).forEach(photo => {
        if (toReturn < photo.size) {
          toReturn = photo.size;
        }
      });
      return (toReturn / (1024 * 1024)).toFixed(1);
    },
    uploadPhoto: function () {
      if (this.getFileSize() > 3) {
        alert(
          "Photo file size too large. Try scaling them down. An easy way to do this on phones is to screenshot the photo."
        );
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      var loadingCount = 0;
      Array.from(this.photos).forEach(photo => {
        uploadGeneralPhoto(this.form, photo)
          .then(response => {
            setTimeout(() => {
              loadingCount++;
              if (loadingCount == Array.from(this.photos).length) {
                this.loading = false;
                this.$emit("cancel", response);
              }
              this.$emit("onPhoto", response);
            }, 2000);
          })
          .catch(error => {
            loadingCount++;
            this.loading = false;
            this.addError(error);
          });
      });
    },
    ...mapActions({
      addError: "errorStore/addError"
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";

.spacer {
  background-color: $DIVIDER_GRAY_COLOR;
  border-left: 1px solid $DIVIDER_GRAY_COLOR;
}
</style>
