<template>
  <base-modal-view title="Recalibrate Vehicle Data" @cancel="cancelClicked">
    <form @submit.prevent="submitClicked" class="flex-column">
      <label class="text-field-title margin-bottom-025 margin-top">Registration Number</label>
      <input required v-model="input.car_registration_number" class="text-field" placeholder="Rego" />
      <label class="text-field-title margin-top margin-bottom-025">Registration State</label>
      <select required class="text-field" v-model="input.car_registration_state">
        <option value="">-- State --</option>
        <option value="SA">SA</option>
        <option value="NSW">NSW</option>
        <option value="VIC">VIC</option>
        <option value="QLD">QLD</option>
        <option value="WA">WA</option>
        <option value="NT">NT</option>
        <option value="TAS">TAS</option>
        <option value="ACT">ACT</option>
      </select>
      <primary-button class="margin-top-1-05" :title="loading ? 'Loading...' : 'Submit'" />
    </form>
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import BaseModalView from "./BaseModalView.vue";

export default {
  name: "RegoStateModal",
  components: { PrimaryButton, BaseModalView },
  props: {
    currentCarRegistrationNumber: {
      type: String,
      default: ""
    },
    currentCarRegistrationState: {
      type: String,
      default: ""
    }
  },
  data: function () {
    return {
      input: {
        car_registration_number: "",
        car_registration_state: ""
      },
      loading: false
    };
  },
  methods: {
    submitClicked: function () {
      if (this.loading) return;
      this.loading = true;
      this.$emit("submitted", this.input);
    },
    cancelClicked: function () {
      this.$emit("cancel");
    }
  },
  mounted: function () {
    this.input.car_registration_number = this.currentCarRegistrationNumber;
    this.input.car_registration_state = this.currentCarRegistrationState;
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";
</style>
